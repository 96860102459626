import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const jwtToken = localStorage.getItem("a_p_user_token")
export const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: jwtToken ? jwtDecode(jwtToken) : {},
  },
  reducers: {
    handleLogin: (state, action) => {
      const token = jwtDecode(action.payload)
      localStorage.setItem("userData", JSON.stringify(token));
      localStorage.setItem("a_p_user_token", action.payload);
      state.userData = token;
      window.location.href = "home"
    },
    handleLogout: (state) => {
      state.userData = {};
      localStorage.removeItem("userData");
      localStorage.removeItem("a_p_user_token");
      window.location.href = "login"
    },
  },
});

export const { handleLogin, handleLogout } = userSlice.actions;

export default userSlice.reducer
